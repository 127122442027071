import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/Login.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layouts/Default.vue'),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
      },
      {
        path: '/business-monitor',
        name: 'business-monitor',
        component: () => import(/* webpackChunkName: "business-monitor" */ '../views/BusinessMonitor.vue')
      },
      {
        path: 'patient',
        name: 'patient-entry',
        component: () => import(/* webpackChunkName: "patient" */ '../views/Patient/Patient.vue')
      },
      {
        path: 'doctor',
        name: 'doctor-entry',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/Doctor/Doctor.vue')
      },
      {
        path: 'doctors',
        name: 'doctor-list',
        component: () => import(/* webpackChunkName: "doctors" */ '../views/Doctor/Doctors.vue')
      },
      {
        path: 'appointment',
        name: 'appointment-entry',
        component: () => import(/* webpackChunkName: "appointment" */ '../views/Doctor/Appointment.vue')
      },
      {
        path: 'appointments',
        name: 'appointment-list',
        component: () => import(/* webpackChunkName: "appointments" */ '../views/Doctor/Appointments.vue')
      },
      {
        path: 'present',
        name: 'present-entry',
        component: () => import(/* webpackChunkName: "present" */ '../views/Doctor/Present.vue')
      },
      {
        path: 'presents',
        name: 'present-list',
        component: () => import(/* webpackChunkName: "presents" */ '../views/Doctor/Presents.vue')
      },
      {
        path: 'account/test-receipt/:id?',
        name: 'test-receipt',
        component: () => import(/* webpackChunkName: "test-receipt" */ '../views/Account/TestReceipt.vue')
      },
      {
        path: 'account/test-receipt-list',
        name: 'test-receipt-list',
        component: () => import(/* webpackChunkName: "test-receipt-list" */ '../views/Account/TestReceiptList.vue')
      },
      {
        path: 'account/test-receipt-slip/:test_receipt_id',
        name: 'test-receipt-slip',
        component: () => import(/* webpackChunkName: "test-receipt-slip" */ '../views/Account/TestReceiptSlip.vue')
      },
      {
        path: 'account/cash-transaction',
        name: 'cash-transaction',
        component: () => import(/* webpackChunkName: "cash-transaction" */ '../views/Account/CashTransaction.vue')
      },
      {
        path: 'account/transaction-invoice/:id',
        name: 'TransactionInvoice',
        component: () => import(/* webpackChunkName: "transaction-invoice" */ '../views/Account/CashTransactionInvoice.vue')
      },
      {
        path: 'account/bank-transaction',
        name: 'bank-transaction',
        component: () => import(/* webpackChunkName: "bank-transaction" */ '../views/Account/BankTransaction.vue')
      },
      {
        path: 'account/patient-payment',
        name: 'patient-payment',
        component: () => import(/* webpackChunkName: "patient-payment" */ '../views/Account/PatientPayment.vue')
      },
      {
        path: 'account/payment-invoice/:id',
        name: 'PaymentInvoice',
        component: () => import(/* webpackChunkName: "payment-invoice" */ '../views/Account/PaymentInvoice.vue')
      },
      {
        path: 'account/supplier-payment',
        name: 'supplier-payment',
        component: () => import(/* webpackChunkName: "supplier-payment" */ '../views/Account/SupplierPayment.vue')
      },
      {
        path: 'account/cash-view',
        name: 'cash-view',
        component: () => import(/* webpackChunkName: "cash-view" */ '../views/Account/CashView.vue')
      },
      {
        path: 'account/account-head/transaction-account',
        name: 'transaction-account',
        component: () => import(/* webpackChunkName: "transaction-account" */ '../views/Account/AccountHead/TransactionAccount.vue')
      },
      {
        path: 'account/account-head/bank-account',
        name: 'bank-account',
        component: () => import(/* webpackChunkName: "bank-account" */ '../views/Account/AccountHead/BankAccount.vue')
      },
      {
        path: 'account/cheque/new-cheque',
        name: 'new-cheque',
        component: () => import(/* webpackChunkName: "new-cheque" */ '../views/Account/Cheque/NewCheque.vue')
      },
      {
        path: 'account/cheque/cheque-list',
        name: 'cheque-list',
        component: () => import(/* webpackChunkName: "cheque-list" */ '../views/Account/Cheque/ChequeList.vue')
      },
      {
        path: 'account/cheque/reminder-cheque-list',
        name: 'reminder-cheque-list',
        component: () => import(/* webpackChunkName: "reminder-cheque-list" */ '../views/Account/Cheque/ReminderChequeList.vue')
      },
      {
        path: 'account/cheque/pending-cheque-list',
        name: 'pending-cheque-list',
        component: () => import(/* webpackChunkName: "pending-cheque-list" */ '../views/Account/Cheque/PendingChequeList.vue')
      },
      {
        path: 'account/cheque/dishonoured-cheque-list',
        name: 'dishonoured-cheque-list',
        component: () => import(/* webpackChunkName: "dishonoured-cheque-list" */ '../views/Account/Cheque/DishonouredChequeList.vue')
      },
      {
        path: 'account/cheque/paid-cheque-list',
        name: 'paid-cheque-list',
        component: () => import(/* webpackChunkName: "paid-cheque-list" */ '../views/Account/Cheque/PaidChequeList.vue')
      },
      {
        path: 'account/report/cash-transaction-report',
        name: 'cash-transaction-report',
        component: () => import(/* webpackChunkName: "cash-transaction-report" */ '../views/Account/Report/CashTransactionReport.vue')
      },
      {
        path: 'account/report/bank-transaction-report',
        name: 'bank-transaction-report',
        component: () => import(/* webpackChunkName: "bank-transaction-report" */ '../views/Account/Report/BankTransactionReport.vue')
      },
      {
        path: 'account/report/cash-statement',
        name: 'cash-statement',
        component: () => import(/* webpackChunkName: "cash-statement" */ '../views/Account/Report/CashStatement.vue')
      },
      {
        path: 'account/report/bank-statement',
        name: 'bank-statement',
        component: () => import(/* webpackChunkName: "bank-statement" */ '../views/Account/Report/BankStatement.vue')
      },
      {
        path: 'account/report/balance-sheet',
        name: 'BalanceSheet',
        component: () => import(/* webpackChunkName: "balance-sheet" */ '../views/Account/Report/BalanceSheet.vue')
      },
      {
        path: 'account/report/day-book',
        name: 'day-book',
        component: () => import(/* webpackChunkName: "day-book" */ '../views/Account/Report/DayBook.vue')
      },
      {
        path: 'employee',
        name: 'employee-entry',
        component: () => import(/* webpackChunkName: "employee-entry" */ '../views/Employee/Employee.vue')
      },
      {
        path: 'employees',
        name: 'employee-list',
        component: () => import(/* webpackChunkName: "employee-list" */ '../views/Employee/Employees.vue')
      },
      {
        path: 'salary-payment',
        name: 'salary-payment',
        component: () => import(/* webpackChunkName: "salary-payment" */ '../views/Employee/SalaryPayment.vue')
      },
      {
        path: 'designation',
        name: 'designation',
        component: () => import(/* webpackChunkName: "designation" */ '../views/Setting/Designation.vue')
      },
      {
        path: 'department',
        name: 'department',
        component: () => import(/* webpackChunkName: "department" */ '../views/Setting/Department.vue')
      },
      {
        path: 'month',
        name: 'month',
        component: () => import(/* webpackChunkName: "month" */ '../views/Setting/Month.vue')
      },
      {
        path: 'supplier',
        name: 'supplier',
        component: () => import(/* webpackChunkName: "supplier" */ '../views/Supplier/Supplier.vue')
      },
      {
        path: 'store/instrument',
        name: 'instrument',
        component: () => import(/* webpackChunkName: "instrument" */ '../views/Store/Instrument/Instrument.vue')
      },
      {
        path: 'store/instrument/category',
        name: 'instrument-category',
        component: () => import(/* webpackChunkName: "instrument-category" */ '../views/Store/Instrument/Category.vue')
      },
      {
        path: 'store/instrument/unit',
        name: 'instrument-unit',
        component: () => import(/* webpackChunkName: "instrument-unit" */ '../views/Store/Instrument/Unit.vue')
      },
      {
        path: 'lab/test',
        name: 'test-entry',
        component: () => import(/* webpackChunkName: "test-entry" */ '../views/Lab/Test.vue')
      },
      {
        path: 'lab/test-report-entry',
        name: 'test-report-entry',
        component: () => import(/* webpackChunkName: "test-report-entry" */ '../views/Lab/TestReportEntry.vue')
      },
      {
        path: 'lab/pharmacist',
        name: 'pharmacist',
        component: () => import(/* webpackChunkName: "pharmacist" */ '../views/Lab/Pharmacist.vue')
      },
      {
        path: 'lab/investigation-report/:id',
        name: 'investigation-report',
        component: () => import(/* webpackChunkName: "investigation-report" */ '../views/Lab/InvestigationReport.vue')
      },
      {
        path: 'lab/test-report-delivery',
        name: 'test-report-delivery',
        component: () => import(/* webpackChunkName: "test-report-delivery" */ '../views/Report-Delivery/TestReportDelivery.vue')
      },
      {
        path: 'lab/delivered-reports',
        name: 'delivered-reports',
        component: () => import(/* webpackChunkName: "delivered-reports" */ '../views/Report-Delivery/TestReportDelivered.vue')
      },
      {
        path: 'lab/commission',
        name: 'commission',
        component: () => import(/* webpackChunkName: "commission" */ '../views/Lab/Commission.vue')
      },
      {
        path: 'lab/commission-payment',
        name: 'commission-payment',
        component: () => import(/* webpackChunkName: "commission-payment" */ '../views/Lab/CommissionPayment.vue')
      },
      {
        path: 'lab/commission-payments',
        name: 'commission-payments',
        component: () => import(/* webpackChunkName: "commission-payment-record" */ '../views/Lab/CommissionPayments.vue')
      },
      {
        path: 'purchase/:id?',
        name: 'purchase-entry',
        component: () => import(/* webpackChunkName: "purchase-entry" */ '../views/Purchase/Purchase.vue')
      },
      {
        path: 'purchase-invoice/:id',
        name: 'purchase-invoice',
        component: () => import(/* webpackChunkName: "purchase-invoice" */ '../views/Purchase/Report/PurchaseInvoice.vue')
      },
      {
        path: 'purchase-record',
        name: 'purchase-record',
        component: () => import(/* webpackChunkName: "purchase-record" */ '../views/Purchase/PurchaseRecord.vue')
      },
      {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/User/User.vue')
      },
      {
        path: 'consumption/:id?',
        name: 'consumption-entry',
        component: () => import(/* webpackChunkName: "consumption-entry" */ '../views/Store/Consumption/Consumption.vue')
      },
      {
        path: 'consumption-invoice/:id',
        name: 'consumption-invoice',
        component: () => import(/* webpackChunkName: "consumption-invoice" */ '../views/Store/Consumption/ConsumptionInvoice.vue')
      },
      {
        path: 'consumption-record',
        name: 'consumption-record',
        component: () => import(/* webpackChunkName: "consumption-record" */ '../views/Store/Consumption/ConsumptionRecord.vue')
      },
      {
        path: 'stock',
        name: 'stock-record',
        component: () => import(/* webpackChunkName: "stock-record" */ '../views/Store/StockReport.vue')
      },
      {
        path: 'appointment-invoice/:id',
        name: 'appointment-invoice',
        component: () => import(/* webpackChunkName: "appointment-invoice" */ '../views/Doctor/Report/AppointmentInvoice.vue')
      },
      {
        path: 'purchase-report',
        name: 'purchase-report',
        component: () => import(/* webpackChunkName: "purchase-report" */ '../views/Store/PurchaseReport.vue')
      },
      {
        path: 'consumption-report',
        name: 'consumption-report',
        component: () => import(/* webpackChunkName: "consumption-report" */ '../views/Store/ConsumptionReport.vue')
      },
      {
        path: 'supplier-due',
        name: 'supplier-due',
        component: () => import(/* webpackChunkName: "supplier-due" */ '../views/Store/Report/SupplierDueReport.vue')
      },
      {
        path: 'patients',
        name: 'patients',
        component: () => import(/* webpackChunkName: "patients" */ '../views/Patient/Patients.vue')
      },
      {
        path: 'salary-sheet',
        name: 'salary-sheet',
        component: () => import(/* webpackChunkName: "salary-sheet" */ '../views/Employee/SalarySheet.vue')
      },
      {
        path: 'company-profile',
        name: 'company-profile',
        component: () => import(/* webpackChunkName: "company-profile" */ '../views/Setting/CompanyProfile.vue')
      },
      {
        path: 'user-profile-setting',
        name: 'user-profile-setting',
        component: () => import(/* webpackChunkName: "user-profile-setting" */ '../views/User/ProfileSetting.vue')
      },
      {
        path: 'account/report/patient-due',
        name: 'patient-due',
        component: () => import(/* webpackChunkName: "patient-due" */ '../views/Account/Report/PatientDueReport.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
