export default {
    namespaced: true,

    state: () => ({
        patientPayments: [],
        newCode: '',
        loading: false,
    }),

    getters: {
        patientPayments(state) {
            return state.patientPayments
        },
        newCode(state) {
            return state.newCode
        },
        loading: state => state.loading,
    },

    mutations: {
        populatePatientPayments(state, patientPayments) {
            state.patientPayments = patientPayments;
        },
        changeCode(state, newCode) {
            state.newCode = newCode
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getPatientPayments(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.post(`get-patient-payments`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populatePatientPayments', res.data.patient_payments);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getPatientPaymentCode(context, payload) {
            await axios.get(`get-patient-payment-code`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async savePatientPayment(context, payload) {
            let isSuccess = {success: false}
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess.success = true
                isSuccess.transId = res.data.id
                context.dispatch('getPatientPaymentCode')
                context.dispatch('getPatientPayments')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return isSuccess
        },

        async deletePatientPayment(context, payload) {
            let success = false
            await axios.post(`delete-patient-payment`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getPatientPayments')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}