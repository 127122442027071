export default {
    namespaced: true,

    state: () => ({
        cheques: [],
        loading: false,
        reminder: [],
    }),

    getters: {
        cheques(state) {
            return state.cheques.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },

        loading(state) {
            return state.loading;
        },

        reminder(state) {
            return state.reminder.map((item, i) => {
                item.sl = i + 1;
                return item 
            });
        }
    },

    mutations: {
        populateCheque(state, cheques) {
            state.cheques = cheques;
        },

        populateLoading(state, loading) {
            state.loading = loading;
        },
        
        populateReminderDate(state, reminder) {
            state.reminder = reminder;
        }
    },

    actions: {
        async getCheques(context, { apiArg = {}, options = {} } = {}) {
            context.commit('populateLoading', true);
            await axios.post('get-cheques', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateCheque', res.data.cheques);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('populateLoading', false);
        },

        async saveCheques(context, cheque) {
            let isSuccess = false;
            let url = 'add-cheque';

            if(cheque.id != null) {
                url = 'update-cheque';
            }

            await axios.post(url, cheque, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getCheques');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteCheque(context, id) {
            let isSuccess = false;
            let url = 'delete-cheque';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getCheques');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },

        async changeStatus(context, id) {
            let isSuccess = false;
            let url = 'change-status';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getCheques');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },

        async reminderChequeData(context) {
            await axios.get('reminder-cheque', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateReminderDate', res.data.remider)
            })
            .catch(err => {
                this.dispatch('snackbar/error', err.response.data.message);
            })
        }
    }
}