export default {
    namespaced: true,

    state: () => ({
        transactionAccounts: [],
        newCode: '',
        loading: false,
    }),

    getters: {
        transactionAccounts: state => state.transactionAccounts,
        newCode: state => state.newCode,
        loading: state => state.loading,
    },

    mutations: {
        populateTransactionAccounts(state, _transactionAccounts) {
            state.transactionAccounts = _transactionAccounts;
        },
        changeCode: (state, _newCode) => state.newCode = _newCode,
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getTransactionAccounts(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.get(`get-transaction-accounts`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateTransactionAccounts', res.data.transaction_accounts);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getTrAccountCode(context, payload) {
            await axios.get(`get-transaction-account-code`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveTransactionAccount(context, payload) {
            let success = false
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getTransactionAccounts')
                context.dispatch('getTrAccountCode')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async deleteTransactionAccount(context, payload) {
            let success = false
            await axios.post(`delete-transaction-account`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getTransactionAccounts')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}