export default {
    namespaced: true,

    state: () => ({
        purchases: [],
        loaging: false,
        invoice: '',
        stock: [],
        invoices: []
    }),

    getters: {
        purchases(state) {
            return state.purchases.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },

        loading(state) {
            return state.loading;
        },

        invoice(state) {
            return state.invoice;
        },

        stock(state) {
            return state.stock;
        },

        invoices(state) {
            return state.invoices.map((item) => {
                return item;
            });
        },
    },

    mutations: {
        populatePurchase(state, purchases) {
            state.purchases = purchases;
        },

        populateLoading(state, loading) {
            state.loaging = loading;
        },
        
        populateInvoice(state, invoice) {
            state.invoice = invoice;
        },

        populateStock(state, stock) {
            state.stock = stock;
        }, 

        populateInvoies(state, invoices) {
            state.invoices = invoices;
        }, 

    },

    actions: {
        async getPurchases(context, apiArg) {
            context.commit('populateLoading', true);
            await axios.post('get-purchases', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populatePurchase', res.data.purchases);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('populateLoading', false);
        },

        async getPurchase(context, payload) {
            let response = null
            await axios.post('get-purchase', payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                response = res.data.purchase
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            return response
        },

        async savePurchase(context, purchase) {
            let response = {isSuccess: false }
            let url = 'add-purchase';

            if(purchase.id != null) {
                url = 'update-purchase';
            }

            await axios.post(url, purchase, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                response.isSuccess = true;
                response.id = res.data.id;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPurchases');
            })
            .catch( err => {
                response.isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return response;
        },

        async deletePurchaseRecord(context, id) {
            let isSuccess = false;
            let url = 'delete-purchase';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPurchases');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },

        async getInvoices(context) {
            await axios.get('get-purchase-invoice', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateInvoice', res.data.code);
            })
            .catch(err => {
                this.dispatch('snackbar/error', error.response.data.message)
            })
        },

        async getInsturmentStock(context, { apiArg = {}, options = {} } = {}) {
            await axios.post('get-stock', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateStock', res.data.stock);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('populateLoading', false);
        },

        async getPurchaseInvoice(context) {
            await axios.get('purchase-invoice', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateInvoies', res.data.invoices);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('populateLoading', false);
        }
    }
}