export default {
    namespaced: true,

    state: () => ({
        instruments: [],
        newCode: '',
        loading: false,
        stock: 0,
        stockReport: []
    }),

    getters: {
        instruments(state) {
            return state.instruments
        },

        newCode(state) {
            return state.newCode
        },

        loading: state => state.loading,

        stock(state) {
            return state.stock;
        },

        stockReport(state) {
            return state.stockReport.map(item => {
                return item;
            });
        }
    },

    mutations: {
        populateInstruments(state, instruments) {
            state.instruments = instruments;
        },

        changeCode(state, newCode) {
            state.newCode = newCode
        },

        changeLoadingStatus: (state, status) => state.loading = status,

        populateStock(state, stock) {
            state.stock = stock;
        }, 

        populateStockReport(state, stockReport) {
            state.stockReport = stockReport;
        }, 
    },

    actions: {
        async getInstruments(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.get(`get-instruments`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateInstruments', res.data.instruments);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getInstrumentCode(context, payload) {
            await axios.get(`get-instrument-code`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveInstrument(context, payload) {
            let success = false
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getInstrumentCode')
                context.dispatch('getInstruments')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async deleteInstrument(context, payload) {
            let success = false
            await axios.post(`delete-instrument`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getInstruments')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async getInstrumentStock(context, id) {
            await axios.post('get-instrument-stock', id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateStock', res.data.stock);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
        },

        async getInstrumentStockReport(context, apiArg) {
            await axios.post('get-stock-report', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateStockReport', res.data.stockReport);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
        }
    }
}