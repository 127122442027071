import Vue from 'vue'
import Vuex from 'vuex'

import layout from './layout'
import snackbar from './snackbar'
import designation from './designation'
import department from './department'
import employee from './employee'
import month from './month'
import doctor from './doctor'
import employeeSalaryPayment from './employee-salary-payment'
import bankAccount from './bank-account'
import transactionAccount from './transaction-account'
import cashTransaction from './cash-transaction'
import bankTransaction from './bank-transaction'
import patient from './patient'
import appointment from './appointment'
import supplier from './supplier'
import cheque from './cheque'
import instrument from './instrument'
import instrumentCategory from './instrument-category'
import instrumentUnit from './instrument-unit'
import test from './test'
import testReceipt from './test-receipt'
import purchase from './purchase'
import consumption from './consumption'
import patientPayment from './patient-payment'
import user from './user'
import auth from './auth'
import companyProfile from './company-profile'
import pharmacist from './pharmacist'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    snackbar,
    designation,
    department,
    employee,
    month,
    doctor,
    employeeSalaryPayment,
    bankAccount,
    transactionAccount,
    cashTransaction,
    bankTransaction,
    patient,
    appointment,
    supplier,
    instrument,
    cheque,
    instrumentCategory,
    instrumentUnit,
    test,
    testReceipt,
    purchase,
    consumption,
    patientPayment,
    user,
    auth,
    companyProfile,
    pharmacist,
  }
})
