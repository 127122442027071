import swal from 'sweetalert'

export default {
    namespaced: true,
    
    state: () => ({
        permittedMenuItems: [],
        userId: null,
        userType: '',
        token: '',
    }),

    getters: {
        jwt: (state) => {
            if (state.token) return state.token
            else return localStorage.getItem('_accessToken')
        },
        permittedMenuItems: (state) => state.permittedMenuItems,
        userId: (state) => state.userId,
        userType: (state) => state.userType,
    },

    mutations: {
        populatePermittedMenuItems: (state, payload) => state.permittedMenuItems = payload,
        changeUserId: (state, payload) => state.userId = payload,
        changeUserType: (state, payload) => state.userType = payload,
        changeTokenValue: (state, payload) => state.token = payload,
    },

    actions: {
        async login(context, payload) {
            let success = false
            await axios.post(`user-login`, payload)
            .then(res => {
                success = true
                context.commit('changeUserId', res.data.id)
                context.commit('changeTokenValue', res.data.token)
                localStorage.setItem('_accessToken', res.data.token)
                localStorage.setItem('_userData', JSON.stringify(res.data.user))
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => swal({
                icon: 'error',
                title: e.response.data.message,
            }))
            return success
        },

        logout() {
            localStorage.setItem('_accessToken', '')
            localStorage.setItem('_userData', '')
        },

        async checkAuth(context) {
            let _res = {success: false}
            await axios.get('check-auth', { 
                headers: { 
                    Authorization: context.getters['jwt'],
                } 
            })
            .then(res => {
                _res.success = true
                _res.user_type = res.data.user.user_type
                let permits = res.data.user.permissions
                _res.permissions = permits != null ? JSON.parse(permits) : []
                context.commit('changeUserId', res.data.user.id)
                context.commit('changeUserType', res.data.user.user_type)
            }).catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return _res
        },

        async savePermissions(context, payload) {
            let success = false
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getUsers')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}