export default {
    namespaced: true,

    state: () => ({
        show: false,
        text: '',
        color: '',
    }),

    getters: {
        show: state => state.show,
        text: state => state.text,
        color: state => state.color,
        timeout: state => state.timeout,
    },

    mutations: {
        populateSnackbar(state, data) {
            state.show = true
            state.text = data.message
            state.color = data.color
        },
        hideSnackbar(state) {
            state.show = false
        }
    },

    actions: {
        async success(context, message) {
            let data = {message, color: 'green darken-2'}
            context.commit('populateSnackbar', data)
            await new Promise(r => setTimeout(r, 3000));
            context.commit('hideSnackbar')
        },
        async error(context, message) {
            let data = {message, color: 'red darken-2'}
            context.commit('populateSnackbar', data)
            await new Promise(r => setTimeout(r, 3000));
            context.commit('hideSnackbar')
        },
    }
}