export default {
    namespaced: true,

    state: () => ({
        bankAccounts: [],
        loading: false,
    }),

    getters: {
        bankAccounts(state) {
            return state.bankAccounts
        },
        loading: state => state.loading,
    },

    mutations: {
        populateBankAccounts(state, _bankAccounts) {
            state.bankAccounts = _bankAccounts;
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getBankAccounts(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.get(`get-bank-accounts`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateBankAccounts', res.data.bank_accounts);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveBankAccount(context, payload) {
            let success = false
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getBankAccounts')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async deleteBankAccount(context, payload) {
            let success = false
            await axios.post(`delete-bank-account`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getBankAccounts')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}