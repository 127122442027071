export default {
    namespaced: true,

    state: () => ({
        cashTransactions: [],
        totalCashBalance: 0,
        newCode: '',
        loading: false,
        cashStatement: {
            purchases: [],
            patients: [],
            suppliers: [],
            cashReceive: [],
            cashPaid: [],
            bankWithdraw: [],
            bankDeposit: [],
            salaryPayment: [],
            commissionPayment: [],
            testReceipts: []
        },
        balanceSheet: {}
    }),

    getters: {
        cashTransactions: state => state.cashTransactions,
        totalCashBalance: state => state.totalCashBalance,
        newCode: state => state.newCode,
        loading: state => state.loading,
        cashStatement: state => state.cashStatement,
        balanceSheet: state => state.balanceSheet,
    },

    mutations: {
        populateCashTransactions: (state, _cashTransactions) => state.cashTransactions = _cashTransactions,
        changeCashBalance: (state, cashBalance) => state.totalCashBalance = cashBalance,
        changeCode: (state, _newCode) => state.newCode = _newCode,
        changeLoadingStatus: (state, status) => state.loading = status,
        populateCashStatement: (state, cashStatement) => state.cashStatement = cashStatement,
        populateBalanceSheet: (state, balanceSheet) => state.balanceSheet = balanceSheet,
    },

    actions: {
        async getCashTransactions(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.post(`get-cash-transactions`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateCashTransactions', res.data.cash_transactions);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getCashTransactionCode(context, payload) {
            await axios.get(`get-cash-transaction-code`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getTotalCashBalance(context) {
            await axios.get(`get-total-cash-balance`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCashBalance', res.data.cashTotal);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveCashTransaction(context, payload) {
            let isSuccess = {success: false}
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess.success = true;
                isSuccess.transId = res.data.id;
                context.dispatch('getCashTransactions')
                context.dispatch('getCashTransactionCode')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return isSuccess
        },

        async deleteCashTransaction(context, payload) {
            let success = false
            await axios.post(`delete-cash-transaction`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getCashTransactions')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async getCashStatements(context, payload) {
            await axios.post(`get-cash-statements`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateCashStatement', res.data.data);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getBalanceSheet(context, payload) {
            await axios.post(`get-balance-sheet`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateBalanceSheet', res.data.balance);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },
    }
}