export default {
    namespaced: true,

    state: () => ({
        suppliers: [],
        newCode: '',
        loading: false,
        payments: [],
        supplierDue: '',
        dueReport: []
    }),

    getters: {
        suppliers(state) {
            return state.suppliers.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },

        payments(state) {
            return state.payments.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },

        newCode(state) {
            return state.newCode;
        },

        loading: state => state.loading,

        supplierDue(state) {
            return state.supplierDue;
        },

        dueReport(state) {
            return state.dueReport;
        }
    },

    mutations: {
        populateSupplier(state, suppliers) {
            state.suppliers = suppliers;
        },
        
        populateSupplierPayment(state, payments) {
            state.payments = payments;
        },

        populateCode(state, code) {
            state.newCode = code;
        },
        
        changeLoadingStatus: (state, status) => state.loading = status,

        populateSupplierDue(state, due) {
            state.supplierDue = due;
        },

        populateSupplierDueReport(state, dueReport) {
            state.dueReport = dueReport;
        }
    },

    actions: {
        async getSuppliers(context) {
            context.commit('changeLoadingStatus', true)
            await axios.get('get-suppliers', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateSupplier', res.data.suppliers);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveSuppliers(context, { supplier, image }) {
            let isSuccess = false;
            let url = 'add-supplier';

            if(supplier.id != null) {
                url = 'update-supplier';
            }

            let fd = new FormData();
            fd.append('supplier', JSON.stringify(supplier));

            if(image != null && image != undefined) {
                fd.append('image', image);
            }

            await axios.post(url, fd, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getCode');
                context.dispatch('getSuppliers');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteSupplier(context, id) {
            let isSuccess = false;
            let url = 'delete-supplier';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSuppliers');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },

        async getCode(context) {
            await axios.get('get-supplier-code', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateCode', res.data.code);
            })
            .catch(err => {
                this.dispatch('snackbar/error', error.response.data.message)
            })
        },

        async getSupplierPayments(context, { apiArg = {}, options = {} } = {}) {
            await axios.post('get-supplier-payment', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateSupplierPayment', res.data.supplierPayments);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
        },

        async getSupplierDue(context, id) {
            await axios.post('get-supplier-due', id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateSupplierDue', res.data.due);
            })
            .catch(err => {
                this.dispatch('snackbar/error', err.message)
            })
        },

        async saveSupplierPayment(context, payment) {
            let isSuccess = false;
            let url = 'supplier-payment';

            if(payment.id != null) {
                url = 'update-supplier-payment';
            }

            await axios.post(url, payment, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSupplierPayments');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteSupplierPayment(context, id) {
            let isSuccess = false;
            let url = 'delete-supplier-payment';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSupplierPayments');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },

        async getSupplierDueReport(context, supplier) {
            let isSuccess = false;
            let url = 'supplier-due-report';

            await axios.post(url, supplier, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                context.commit('populateSupplierDueReport', res.data.supplierDue);
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },
    }
}