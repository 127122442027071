export default {
    namespaced: true,

    state: () => ({
        appointments: [],
        loading: false,
    }),

    getters: {
        appointments(state) {
            return state.appointments.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },
        loading: state => state.loading,
    },

    mutations: {
        populateAppointment(state, appointments) {
            state.appointments = appointments;
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getAppointments(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.post('get-appointments', payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateAppointment', res.data.appointments);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveAppointment(context, payload) {
            let response = {isSuccess: false }
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                response.isSuccess = true;
                response.id = res.data.id;
                context.dispatch('getAppointments')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return response
        },

        async deleteAppointment(context, payload) {
            let success = false
            await axios.post(`delete-appointment`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getAppointments')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        }
    }
}