<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    let hasToken = localStorage.getItem('_accessToken')
    if (!hasToken) this.$router.push('/login')
  },
};
</script>