import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/main.scss'
import vuetify from './plugins/vuetify';

import axios from 'axios'

let devAPI = 'http://127.0.0.1:8000'
let productionAPI = 'http://carelabbd.com/backend'
// let productionAPI = 'https://diagnostic-api.bigsoftwareltd.com'

window.apiBaseURL = process.env.NODE_ENV == 'development' ? devAPI : productionAPI

const Axios = axios.create({
  baseURL: `${apiBaseURL}/api/`,
});

window.axios = Axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
