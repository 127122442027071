export default {
    namespaced: true,

    state: () => ({
        pharmacists: [],
        loading: false,
    }),

    getters: {
        pharmacists(state) {
            return state.pharmacists.map((item, i) => {
                item.sl = i + 1;
                item.display_text = `${item.code} - ${item.name} - ${item.phone}`;
                return item;
            });
        },

        loading: state => state.loading,
    },

    mutations: {
        populatePharmacist(state, pharmacists) {
            state.pharmacists = pharmacists
        },

        changeLoadingStatus: (state, status) => state.loading = status,

    },

    actions: {
        async getPharmacists(context, apiArg) {
            context.commit('changeLoadingStatus', true)
            await axios.post('get-pharmacists', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populatePharmacist', res.data.pharmacists);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async savePharmacist(context, pharmacist) {
            let isSuccess = false;
            let url = 'add-pharmacist';

            if(pharmacist.id != null) {
                url = 'update-pharmacist';
            }

            await axios.post(url, pharmacist, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPharmacists');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deletePharmacist(context, id) {
            let isSuccess = false;
            let url = 'delete-pharmacist';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPharmacists');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        }
    }
}