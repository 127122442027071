export default {
    namespaced: true,

    state: () => ({
        menuItems: [
            {
                name: "Dashboard",
                icon: "mdi-apps",
                color: "teal accent-3",
                link: '/',
                routeName: 'dashboard',
            },
            {
                name: "Doctor Module",
                icon: "mdi-account-tie",
                color: "green lighten-3",
                locked: true,
                children: [
                    {
                        name: "Doctor Entry",
                        link: "/doctor",
                        color: "green lighten-3",
                        icon: "mdi-account-plus",
                        routeName: 'doctor-entry',
                    },
                    {
                        name: "Doctor List",
                        link: "/doctors",
                        color: "green lighten-3",
                        icon: "mdi-clipboard-list",
                        routeName: 'doctor-list',
                    },
                    {
                        name: "Appointment Entry",
                        link: "/appointment",
                        color: "green lighten-3",
                        icon: "mdi-alpha-a-circle",
                        routeName: 'appointment-entry',
                    },
                    {
                        name: "Appointment List",
                        link: "/appointments",
                        color: "green lighten-3",
                        icon: "mdi-clipboard-list",
                        routeName: 'appointment-list',
                    },
                    // {
                    //     name: "Present Entry",
                    //     link: "/present",
                    //     color: "green lighten-3",
                    //     icon: "mdi-alpha-p-circle",
                    // },
                    // {
                    //     name: "Present List",
                    //     link: "/presents",
                    //     color: "green lighten-3",
                    //     icon: "mdi-clipboard-list",
                    // },
                    // {
                    //     name: "Report",
                    //     link: "/",
                    //     color: "green lighten-3",
                    //     icon: "mdi-clipboard-text",
                    // },
                ],
            },
            {
                name: "Accounts Module",
                icon: "mdi-currency-usd",
                color: "cyan accent-3",
                locked: true,
                children: [
                    {
                        name: "Test Receipt",
                        link: "/account/test-receipt",
                        color: "cyan accent-3",
                        icon: "mdi-receipt",
                        routeName: 'test-receipt',
                    },
                    {
                        name: "Test Receipt List",
                        link: "/account/test-receipt-list",
                        color: "cyan accent-3",
                        icon: "mdi-view-list",
                        routeName: 'test-receipt-list',
                    },
                    {
                        name: "Cash Transaction",
                        link: "/account/cash-transaction",
                        color: "cyan accent-3",
                        icon: "mdi-account-cash",
                        routeName: 'cash-transaction',
                    },
                    {
                        name: "Bank Transation",
                        link: "/account/bank-transaction",
                        color: "cyan accent-3",
                        icon: "mdi-bank-transfer",
                        routeName: 'bank-transaction',
                    },
                    {
                        name: "Patient Payment",
                        link: "/account/patient-payment",
                        color: "cyan accent-3",
                        icon: "mdi-bank-transfer-in",
                        routeName: 'patient-payment',
                    },
                    {
                        name: "Supplier Payment",
                        link: "/account/supplier-payment",
                        color: "cyan accent-3",
                        icon: "mdi-bank-transfer-out",
                        routeName: 'supplier-payment',
                    },
                    {
                        name: "Cash View",
                        link: "/account/cash-view",
                        color: "cyan accent-3",
                        icon: "mdi-cash",
                        routeName: 'cash-view',
                    },
                    {
                        name: "Account Head",
                        color: "cyan accent-3",
                        icon: "mdi-clipboard-list-outline",
                        locked: true,
                        children: [
                            {
                                name: "Transaction Accounts",
                                link: "/account/account-head/transaction-account",
                                color: "cyan accent-3",
                                icon: "mdi-ballot",
                                routeName: 'transaction-account',
                            },
                            {
                                name: "Bank Accounts",
                                link: "/account/account-head/bank-account",
                                color: "cyan accent-3",
                                icon: "mdi-bank-outline",
                                routeName: 'bank-account',
                            },
                        ]
                    },
                    {
                        name: "Cheque",
                        color: "cyan accent-3",
                        icon: "mdi-cash-check",
                        locked: true,
                        children: [
                            {
                                name: "New Cheque Entry",
                                link: "/account/cheque/new-cheque",
                                color: "cyan accent-3",
                                icon: "mdi-card-plus-outline",
                                routeName: 'new-cheque',
                            },
                            {
                                name: "Cheque List",
                                link: "/account/cheque/cheque-list",
                                color: "cyan accent-3",
                                icon: "mdi-card-text-outline",
                                routeName: 'cheque-list',
                            },
                            {
                                name: "Reminder Cheque List",
                                link: "/account/cheque/reminder-cheque-list",
                                color: "cyan accent-3",
                                icon: "mdi-card-text",
                                routeName: 'reminder-cheque-list',
                            },
                            {
                                name: "Pending Cheque List",
                                link: "/account/cheque/pending-cheque-list",
                                color: "cyan accent-3",
                                icon: "mdi-card-bulleted-off",
                                routeName: 'pending-cheque-list',
                            },
                            {
                                name: "Dishonoured Cheque List",
                                link: "/account/cheque/dishonoured-cheque-list",
                                color: "cyan accent-3",
                                icon: "mdi-card-bulleted-off-outline",
                                routeName: 'dishonoured-cheque-list',
                            },
                            {
                                name: "Paid Cheque List",
                                link: "/account/cheque/paid-cheque-list",
                                color: "cyan accent-3",
                                icon: "mdi-cash-check",
                                routeName: 'paid-cheque-list',
                            },
                        ]
                    },
                    {
                        name: "Reports",
                        color: "cyan accent-3",
                        icon: "mdi-clipboard-list",
                        locked: true,
                        children: [
                            {
                                name: "Cash Trans. Report",
                                link: "/account/report/cash-transaction-report",
                                color: "cyan accent-3",
                                icon: "mdi-cash",
                                routeName: 'cash-transaction-report',
                            },
                            {
                                name: "Bank Trans. Report",
                                link: "/account/report/bank-transaction-report",
                                color: "cyan accent-3",
                                icon: "mdi-bank",
                                routeName: 'bank-transaction-report',
                            },
                            {
                                name: "Cash Statement Report",
                                link: "/account/report/cash-statement",
                                color: "cyan accent-3",
                                icon: "mdi-clipboard-text-multiple-outline",
                                routeName: 'cash-statement',
                            },
                            {
                                name: "Bank Statement Report",
                                link: "/account/report/bank-statement",
                                color: "cyan accent-3",
                                icon: "mdi-clipboard-text-multiple-outline",
                                routeName: 'bank-statement',
                            },
                            {
                                name: "Patient Due",
                                link: "/account/report/patient-due",
                                color: "cyan accent-3",
                                icon: "mdi-cash",
                                routeName: 'patient-due',
                            },
                            {
                                name: "Balance Sheet",
                                link: "/account/report/balance-sheet",
                                color: "cyan accent-3",
                                icon: "mdi-clipboard-text-multiple-outline",
                            },
                            // {
                            //     name: "Day Book",
                            //     link: "/account/report/day-book",
                            //     color: "cyan accent-3",
                            //     icon: "mdi-clipboard-text-multiple-outline",
                            // },
                        ]
                    },
                ],
            },
            {
                name: "Store Module",
                icon: "mdi-cart",
                color: "orange lighten-3",
                locked: true,
                children: [
                    {
                        name: "Purchase Entry",
                        link: "/purchase",
                        color: "orange lighten-3",
                        icon: "mdi-cart-arrow-down",
                        routeName: 'purchase-entry',
                    },
                    {
                        name: "Purchase Record",
                        link: "/purchase-record",
                        color: "orange lighten-3",
                        icon: "mdi-clipboard-list",
                        routeName: 'purchase-record',
                    },
                    {
                        name: "Stock Report",
                        link: "/stock",
                        color: "orange lighten-3",
                        icon: "mdi-clipboard-list",
                        routeName: 'stock-record',
                    },
                    {
                        name: "Consumption Entry",
                        link: "/consumption",
                        color: "orange lighten-3",
                        icon: "mdi-cash-100",
                        routeName: 'consumption-entry',
                    },
                    {
                        name: "Consumption Record",
                        link: "/consumption-record",
                        color: "orange lighten-3",
                        icon: "mdi-clipboard-list",
                        routeName: 'consumption-record',
                    },
                    {
                        name: "Lab Instrument",
                        icon: "mdi-filter",
                        color: "orange lighten-3",
                        locked: true,
                        children: [
                            {
                                name: "Instrument",
                                link: "/store/instrument",
                                color: "orange lighten-3",
                                icon: "mdi-filter-plus",
                                routeName: 'instrument',
                            },
                            {
                                name: "Category",
                                link: "/store/instrument/category",
                                color: "orange lighten-3",
                                icon: "mdi-format-list-numbered",
                                routeName: 'instrument-category',
                            },
                            {
                                name: "Unit",
                                link: "/store/instrument/unit",
                                color: "orange lighten-3",
                                icon: "mdi-format-list-numbered",
                                routeName: 'instrument-unit',
                            },
                        ]
                    },
                    {
                        name: "Reports",
                        color: "orange lighten-3",
                        icon: "mdi-clipboard-list",
                        locked: true,
                        children: [
                            {
                                name: "Purchase Invoice",
                                link: "/purchase-report",
                                color: "orange lighten-3",
                                icon: "mdi-cart",
                                routeName: 'purchase-invoice',
                            },
                            {
                                name: "Consumption Invoice",
                                link: "/consumption-report",
                                color: "orange lighten-3",
                                icon: "mdi-dolly",
                                routeName: 'consumption-invoice',
                            },
                            {
                                name: "Supplier Due",
                                link: "/supplier-due",
                                color: "orange lighten-3",
                                icon: "mdi-cash",
                                routeName: 'supplier-due',
                            },
                        ]
                    },
                ],
            },
            {
                name: "Employee Module",
                icon: "mdi-account-supervisor",
                color: "green accent-3",
                locked: true,
                children: [
                    {
                        name: "Employee Entry",
                        link: "/employee",
                        color: "green accent-3",
                        icon: "mdi-account-plus",
                        routeName: 'employee-entry',
                    },
                    {
                        name: "Employee List",
                        link: "/employees",
                        color: "green accent-3",
                        icon: "mdi-account-multiple",
                        routeName: 'employee-list',
                    },
                    {
                        name: "Salary Payment",
                        link: "/salary-payment",
                        color: "green accent-3",
                        icon: "mdi-currency-bdt",
                        routeName: 'salary-payment',
                    },
                    {
                        name: "Salary Sheet",
                        link: "/salary-sheet",
                        color: "green accent-3",
                        icon: "mdi-currency-eth",
                        routeName: '',
                    },
                ],
            },
            {
                name: "Lab Module",
                icon: "mdi-flask",
                color: "orange darken-3",
                locked: true,
                children: [
                    {
                        name: "Test Entry",
                        link: "/lab/test",
                        color: "orange darken-3",
                        icon: "mdi-flask-plus",
                        routeName: 'test-entry',
                    },
                    {
                        name: "Report Entry",
                        link: "/lab/test-report-entry",
                        color: "orange darken-3",
                        icon: "mdi-format-list-checks",
                        routeName: 'test-report-entry',
                    },
                    {
                        name: "Pharmacist Entry",
                        link: "/lab/pharmacist",
                        color: "orange darken-3",
                        icon: "mdi-account-plus",
                        routeName: 'pharmacist',
                    },
                    {
                        name: "Commission Entry",
                        link: "/lab/commission",
                        color: "orange darken-3",
                        icon: "mdi-currency-bdt",
                        routeName: 'commission',
                    },
                    {
                        name: "Commission Payment",
                        link: "/lab/commission-payment",
                        color: "orange darken-3",
                        icon: "mdi-currency-bdt",
                        routeName: 'commission-payment',
                    },
                    {
                        name: "Commission Record",
                        link: "/lab/commission-payments",
                        color: "orange darken-3",
                        icon: "mdi-currency-bdt",
                        routeName: 'commission-payments',
                    },
                ],
            },
            {
                name: "Delivery Module",
                icon: "mdi-file",
                color: "blue darken-1",
                locked: true,
                children: [
                    {
                        name: "Report Delivery",
                        link: "/lab/test-report-delivery",
                        color: "blue darken-1",
                        icon: "mdi-google-analytics",
                        routeName: 'test-report-delivery',
                    },
                    {
                        name: "Delivered Report",
                        link: "/lab/delivered-reports",
                        color: "blue darken-1",
                        icon: "mdi-archive-arrow-up-outline",
                        routeName: 'delivered-reports',
                    },
                ],
            },
            {
                name: "Settings",
                icon: "mdi-brightness-7",
                color: "teal lighten-3",
                locked: true,
                children: [
                    {
                        name: "Company Profile",
                        link: "/company-profile",
                        color: "teal lighten-3",
                        icon: "mdi-information",
                        routeName: 'company-profile',
                    },
                    {
                        name: "Supplier Entry",
                        link: "/supplier",
                        color: "teal lighten-3",
                        icon: "mdi-human-baby-changing-table",
                        routeName: 'supplier',
                    },
                    {
                        name: "Patient Entry",
                        link: "/patient",
                        color: "teal lighten-3",
                        icon: "mdi-human",
                        routeName: 'patient-entry',
                    },
                    {
                        name: "Patient List",
                        link: "/patients",
                        color: "teal lighten-3",
                        icon: "mdi-human",
                        routeName: 'patient-entry',
                    },
                    {
                        name: "Designations",
                        link: "/designation",
                        color: "teal lighten-3",
                        icon: "mdi-post",
                        routeName: 'designation',
                    },
                    {
                        name: "Departments",
                        link: "/department",
                        color: "teal lighten-3",
                        icon: "mdi-form-select",
                        routeName: 'department',
                    },
                    {
                        name: "Month",
                        link: "/month",
                        color: "teal lighten-3",
                        icon: "mdi-calendar-month",
                        routeName: 'month',
                    },
                    {
                        name: "SMS Blast",
                        link: "/",
                        color: "teal lighten-3",
                        icon: "mdi-cellphone-message",
                        routeName: '',
                    },
                    {
                        name: "User",
                        link: "/user",
                        color: "teal lighten-3",
                        icon: "mdi-account-circle",
                        routeName: 'user',
                    },
                ],
            },
        ],
    }),

    getters: {
        menuItems: (state) => state.menuItems,
    },

    mutations: {
        
    },

    actions: {

    }
}