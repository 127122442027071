export default {
    namespaced: true,

    state: () => ({
        months: [],
        loading: false,
    }),

    getters: {
        months(state) {
            return state.months.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },
        loading: state => state.loading,
    },

    mutations: {
        populateMonth(state, months) {
            state.months = months;
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getMonth(context) {
            context.commit('changeLoadingStatus', true)
            await axios.get('get-month', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateMonth', res.data.months);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveMonth(context, month) {
            let isSuccess = false;
            let url = 'add-month';

            if(month.id != null) {
                url = 'update-month';
            }

            await axios.post(url, month, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMonth');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteMonth(context, id) {
            let isSuccess = false;
            let url = 'delete-month';

            await axios.post(url, { id }, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMonth');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        }
    }
}