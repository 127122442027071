export default {
    namespaced: true,

    state: () => ({
        doctors: [],
        newCode: '',
        loading: false,
    }),

    getters: {
        doctors(state) {
            return state.doctors.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },

        newCode(state) {
            return state.newCode;
        },
        loading: state => state.loading,
    },

    mutations: {
        populateDoctor(state, doctors) {
            state.doctors = doctors;
        },

        populateCode(state, code) {
            state.newCode = code;
        },
        changeLoadingStatus: (state, status) => state.loading = status,        
    },

    actions: {
        async getDoctors(context) {
            context.commit('changeLoadingStatus', true)
            await axios.get('get-doctors', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateDoctor', res.data.doctors);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveDoctor(context, { doctor, image }) {
            let isSuccess = false;
            let url = 'add-doctor';

            if(doctor.id != null) {
                url = 'update-doctor';
            }

            let fd = new FormData();
            fd.append('doctor', JSON.stringify(doctor));

            if(image != null && image != undefined) {
                fd.append('image', image);
            }

            await axios.post(url, fd, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDoctors');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteDoctor(context, id) {
            let isSuccess = false;
            let url = 'delete-doctor';

            await axios.post(url, { id }, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDoctors');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },

        async getCode(context) {
            await axios.get('get-doctor-code', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateCode', res.data.code);
            })
            .catch(err => {
                this.dispatch('snackbar/error', error.response.data.message)
            })
        }
    }
}