export default {
    namespaced: true,

    state: () => ({
        users: [],
        loading: false,
    }),

    getters: {
        users(state) {
            return state.users
        },
        loading: state => state.loading,
    },

    mutations: {
        populateUsers(state, users) {
            state.users = users;
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getUsers(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.get(`get-users`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateUsers', res.data.users);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getCurrentUser() {
            let response = {success: false}
            await axios.get('get-current-user', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                response.success = true
                response.user = res.data.user
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            return response
        },

        async saveUser(context, payload) {
            let success = false
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getUsers')
                this.dispatch('auth/checkAuth')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async deleteUser(context, payload) {
            let success = false
            await axios.post(`delete-user`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getUsers')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}