export default {
    namespaced: true,

    state: () => ({
        instrumentUnits: [],
        loading: false,
    }),

    getters: {
        instrumentUnits(state) {
            return state.instrumentUnits.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },
        loading: state => state.loading,
    },

    mutations: {
        populateInstrumentUnits(state, instrumentUnits) {
            state.instrumentUnits = instrumentUnits;
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getInstrumentUnits(context) {
            context.commit('changeLoadingStatus', true)
            await axios.get('get-instrument-units', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateInstrumentUnits', res.data.instrumentUnits);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveInstrumentUnit(context, instrumentUnit) {
            let isSuccess = false;
            let url = 'add-instrument-unit';

            if(instrumentUnit.id != null) {
                url = 'update-instrument-unit';
            }

            await axios.post(url, instrumentUnit, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getInstrumentUnits');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteInstrumentUnit(context, id) {
            let isSuccess = false;
            let url = 'delete-instrument-unit';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getInstrumentUnits');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        }
    }
}