export default {
    namespaced: true,

    state: () => ({
        instrumentCategories: [],
        loading: false,
    }),

    getters: {
        instrumentCategories(state) {
            return state.instrumentCategories.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },
        loading: state => state.loading,
    },

    mutations: {
        populateInstrumentCategory(state, instrumentCategories) {
            state.instrumentCategories = instrumentCategories;
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getInstrumentCategories(context) {
            context.commit('changeLoadingStatus', true)
            await axios.get('get-instrument-categories', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateInstrumentCategory', res.data.instrumentCategories);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveInstrumentCategory(context, instrumentCategory) {
            let isSuccess = false;
            let url = 'add-instrument-category';

            if(instrumentCategory.id != null) {
                url = 'update-instrument-category';
            }

            await axios.post(url, instrumentCategory, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getInstrumentCategories');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteInstrumentCategory(context, id) {
            let isSuccess = false;
            let url = 'delete-instrument-category';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getInstrumentCategories');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        }
    }
}