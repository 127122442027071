export default {
    namespaced: true,

    state: () => ({
        salaryPayments: [],
        payableAmount: 0,
        loading: false,
    }),

    getters: {
        salaryPayments: (state) => state.salaryPayments,
        payableAmount: (state) => state.payableAmount,
        loading: state => state.loading,
    },

    mutations: {
        populateSalaryPayments: (state, data) => state.salaryPayments = data,
        setPayableAmount: (state, amount) => state.payableAmount = amount,
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getSalaryPayments(context, apiArg) {
            context.commit('changeLoadingStatus', true)
            await axios.post('get-salary-payments', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateSalaryPayments', res.data.salary_payments);
            })
            .catch(err => this.dispatch('snackbar/error', err.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getPayableAmount(context, payload) {
            await axios.post('get-payable-amount', payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('setPayableAmount', res.data.payable_amount);
            })
            .catch(err => this.dispatch('snackbar/error', err.response.data.message))
        },

        async addEmployeeSalaryPayment(context, data) {
            let isSuccess = false
            await axios.post('add-salary-payment', data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true
                context.dispatch('getSalaryPayments');
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(err => this.dispatch('snackbar/error', err.response.data.message))
            return isSuccess
        },

        async updateEmployeeSalaryPayment(context, data) {
            let isSuccess = false
            await axios.post('update-salary-payment', data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true
                context.dispatch('getSalaryPayments');
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(err => this.dispatch('snackbar/error', err.response.data.message))
            return isSuccess
        },

        async deleteEmployeeSalaryPayment(context, payload) {
            let success = false
            await axios.post('delete-salary-payment', payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getSalaryPayments');
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}