export default {
    namespaced: true,

    state: () => ({
        designations: [],
        loading: false,
    }),

    getters: {
        designations(state) {
            return state.designations.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },
        loading: state => state.loading,
    },

    mutations: {
        populateDesignations(state, designations) {
            state.designations = designations;
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getDesignations(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.get('get-designations', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateDesignations', res.data.designations);
            })
            .catch(err => this.dispatch('snackbar/error', err.message))
            context.commit('changeLoadingStatus', false)
        },

        async saveDesignation(context, designation) {
            let isSuccess = false;
            let url = 'add-designation';

            if(designation.id != null) {
                url = 'update-designation';
            }

            await axios.post(url, designation, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDesignations');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deleteDesignation(context, id) {
            let isSuccess = false;
            let url = 'delete-designation';

            await axios.post(url, { id }, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDesignations');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        }
    }
}