export default {
    namespaced: true,

    state: () => ({
        bankTransactions: [],
        currentBankBalance: 0,
        totalBankBalance: 0,
        allBankCurrentBalance: [],
        loading: false,
        bankStatement: {
            accounts: [],
            patients: [],
            suppliers: [],
            bankWithdraw: [],
            bankDeposit: [],
            commissionPayment: [],
        }
    }),

    getters: {
        bankTransactions: state => state.bankTransactions,
        currentBankBalance: state => state.currentBankBalance,
        totalBankBalance: state => state.totalBankBalance,
        allBankCurrentBalance: state => state.allBankCurrentBalance,
        loading: state => state.loading,
        bankStatement: state => state.bankStatement
    },

    mutations: {
        populateBankTransactions(state, _bankTransactions) {
            state.bankTransactions = _bankTransactions;
        },
        changeCurrentBankBalance: (state, currentBalance) => state.currentBankBalance = currentBalance,
        changeTotalBankBalance: (state, totalBalance) => state.totalBankBalance = totalBalance,
        populateAllBankCurrentBalance: (state, _data) => state.allBankCurrentBalance = _data,
        changeLoadingStatus: (state, status) => state.loading = status,
        populateBankStatement: (state, statement) => state.bankStatement = statement
    },

    actions: {
        async getBankTransactions(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.post(`get-bank-transactions`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateBankTransactions', res.data.bank_transactions);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getAllBankCurrentBalance(context, payload) {
            await axios.get(`get-all-bank-current-balance`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateAllBankCurrentBalance', res.data.all_bank_balance);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getCurrentBankBalance(context, payload) {
            await axios.post(`get-current-bank-balance`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                if (res.data.current_balance != null)
                context.commit('changeCurrentBankBalance', res.data.current_balance);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getTotalBankBalance(context) {
            await axios.get(`get-total-bank-balance`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                if (res.data.total_bank_balance != null)
                context.commit('changeTotalBankBalance', res.data.total_bank_balance);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveBankTransaction(context, payload) {
            let success = false
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getBankTransactions')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async deleteBankTransaction(context, payload) {
            let success = false
            await axios.post(`delete-bank-transaction`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getBankTransactions')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async getBankStatements(context, payload) {
            await axios.post(`get-bank-statements`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateBankStatement', res.data.statements);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },
    }
}