export default {
    namespaced: true,

    state: () => ({
        tests: [],
        newCode: '',
        loading: false,
    }),

    getters: {
        tests(state) {
            return state.tests
        },
        newCode(state) {
            return state.newCode
        },
        loading: state => state.loading,
    },

    mutations: {
        populateTests(state, tests) {
            state.tests = tests;
        },
        changeCode(state, newCode) {
            state.newCode = newCode
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getTests(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.get(`get-tests`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateTests', res.data.tests);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getTestCode(context, payload) {
            await axios.get(`get-test-code`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveTest(context, payload) {
            let success = false
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getTestCode')
                context.dispatch('getTests')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async deleteTest(context, payload) {
            let success = false
            await axios.post(`delete-test`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getTests')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}