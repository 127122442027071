export default {
    namespaced: true,

    state: () => ({
        employees: [],
        newCode: '',
        loading: false,
    }),

    getters: {
        employees(state) {
            return state.employees
        },
        newCode(state) {
            return state.newCode
        },
        loading: state => state.loading,
    },

    mutations: {
        populateEmployees(state, employees) {
            state.employees = employees;
        },
        changeCode(state, newCode) {
            state.newCode = newCode
        },
        changeLoadingStatus: (state, status) => state.loading = status,
    },

    actions: {
        async getEmployees(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.get(`get-employees`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateEmployees', res.data.employees);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getEmployeeCode(context, payload) {
            await axios.get(`get-employee-code`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveEmployee(context, payload) {
            let success = false
            await axios.post(`add-employee`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    'content-type': 'multipart/form-data'
                }
            })
            .then(res => {
                success = true
                context.dispatch('getEmployeeCode')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async updateEmployee(context, payload) {
            let success = false
            await axios.post(`update-employee`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    'content-type': 'multipart/form-data'
                }
            })
            .then(res => {
                success = true
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },

        async deleteEmployee(context, payload) {
            let success = false
            await axios.post(`delete-employee`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getEmployees')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
    }
}