export default {
    namespaced: true,

    state: () => ({
        patients: [],
        newCode: '',
        loading: false,
        patientPreviousDue: 0,
        patientDue: 0,
        dueReport: []
    }),

    getters: {
        patients(state) {
            return state.patients.map((item, i) => {
                item.sl = i + 1;
                return item;
            });
        },

        newCode(state) {
            return state.newCode;
        },

        loading: state => state.loading,

        patientPreviousDue: state => state.patientPreviousDue,

        patientDue: state => state.patientDue,
        
        patientDueReport(state) {
            return state.dueReport;
        }
    },

    mutations: {
        populatePatient(state, _patients) {
            state.patients = _patients
        },

        populateCode(state, code) {
            return state.newCode = code;
        },

        changeLoadingStatus: (state, status) => state.loading = status,

        changePatientPreviousDue: (state, due) => state.patientPreviousDue = due,

        patientTotalDue: (state, due) => state.patientDue = due,

        populateDueReport(state, dueReport) {
            state.dueReport = dueReport;
        }
    },

    actions: {
        async getPatients(context, apiArg) {
            context.commit('changeLoadingStatus', true)
            await axios.post('get-patients', apiArg, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populatePatient', res.data.patients);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
            context.commit('changeLoadingStatus', false)
        },

        async getPatientCode(context) {
            await axios.get('get-patient-code', {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
        },

        async savePatients(context, patient) {
            let isSuccess = false;
            let url = 'add-patient';

            if(patient.id != null) {
                url = 'update-patient';
            }

            await axios.post(url, patient, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            }) 
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPatients');
            })
            .catch( err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },

        async deletePatient(context, id) {
            let isSuccess = false;
            let url = 'delete-patient';

            await axios.post(url, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPatients');
            })
            .catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })
            
            return isSuccess;
        },

        async getPatientPreviousDue(context, payload) {
            await axios.post(`get-patient-previous-due`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                if (res.data.previous_due != null)
                context.commit('changePatientPreviousDue', res.data.previous_due);
                else context.commit('changePatientPreviousDue', 0);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getPatientTotalDue(context) {
            await axios.get(`get-patient-due`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                if (res.data.totalDue != null)
                context.commit('patientTotalDue', res.data.totalDue);
                else context.commit('patientTotalDue', 0);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },
        async getPatientTotalDue(context) {
            await axios.get(`get-patient-due`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                if (res.data.totalDue != null)
                context.commit('patientTotalDue', res.data.totalDue);
                else context.commit('patientTotalDue', 0);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getPatientDueReport(context, due) {
            await axios.post(`get-due-report`, due, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateDueReport', res.data.reports);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },
    }
}