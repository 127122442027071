export default {
    namespaced: true,

    state: () => ({
        testReceipts: [],
        testReceipt: null,
        newCode: '',
        loading: false,
        todayCollection: 0,
        lastMonthCollection: 0,
        commissionPayments: [],
        due: 0
    }),

    getters: {
        testReceipts(state) {
            return state.testReceipts
        },
        commissionPayments(state) {
            return state.commissionPayments
        },
        newCode(state) {
            return state.newCode
        },
        due: state => state.due,
        loading: state => state.loading,
        testReceipt: state => state.testReceipt,

        todayCollection: state => state.todayCollection,

        lastMonthCollection: state => state.lastMonthCollection,
    },

    mutations: {
        populateTestReceipts(state, testReceipts) {
            state.testReceipts = testReceipts;
        },
        populateCommissionPayment(state, commissionPayments) {
            state.commissionPayments = commissionPayments;
        },
        changeCode(state, newCode) {
            state.newCode = newCode
        },
        populateDue(state, due){
            state.due = due
        },
        changeLoadingStatus: (state, status) => state.loading = status,
        populateTestReceipt: (state, _testReceipt) => state.testReceipt = _testReceipt,

        populateTodayCollection: (state, todayCollection) => state.todayCollection = todayCollection,

        populateLastMonthCollection: (state, lastMonthCollection) => state.lastMonthCollection = lastMonthCollection,
    },

    actions: {
        async getTestReceipts(context, payload) {
            context.commit('changeLoadingStatus', true)
            await axios.post(`get-test-receipts`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateTestReceipts', res.data.test_receipts);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            context.commit('changeLoadingStatus', false)
        },

        async getTestReceipt(context, payload) {
            await axios.post(`get-test-receipt`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateTestReceipt', res.data.test_receipt);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getTestReceiptDetailsById(context, payload) {
            let testReceiptDetails = null

            await axios.get(`get-test-receipt-details/${payload.id}`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                testReceiptDetails = res.data.test_receipt_details;
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))

            return testReceiptDetails
        },

        async getTestReceiptCode(context, payload) {
            await axios.get(`get-test-receipt-code`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('changeCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveTestReceipt(context, payload) {
            let _res = {success: false}
            await axios.post(payload.url, payload.data, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                _res.success = true
                _res.testReceiptId = res.data.test_receipt_id
                context.dispatch('getTestReceiptCode')
                context.dispatch('getTestReceipts')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return _res
        },

        async deleteTestReceipt(context, payload) {
            let success = false
            await axios.post(`delete-test-receipt`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getTestReceipts')
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
        
        async saveCompletedTest(context, payload) {
            let success = false
            await axios.post(`save-completed-test`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getTestReceipts', {isCompleted: false})
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
        
        async saveDeliveryTest(context, payload) {
            let success = false
            await axios.post(`save-delivery-test`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                success = true
                context.dispatch('getTestReceipts', {isCompleted: true, isDelivered: false})
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            return success
        },
        
        async getTodayCollection(context) {
            await axios.get(`today-collection`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateTodayCollection', res.data.todayCollection);
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
        },

        async getLastMonthCollection(context) {
            await axios.get(`last-month-collection`, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateLastMonthCollection', res.data.monthCollection);
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
        },
        
        async saveTestInvestigation(context, payload) {
            let isSuccess = false
            
            await axios.post(`save-test-investigation`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true
                this.dispatch('snackbar/success', res.data.message)
                context.dispatch('getTestReceipts')
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            
            return isSuccess
        },

        async saveCommission(context, payload) {
            let isSuccess = false
            
            await axios.post(`save-rf-commission`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true
                this.dispatch('snackbar/success', res.data.message)
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
            
            return isSuccess
        },

        async getCommissionPayments(context, payload) {
            await axios.post(`get-commission-payments`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateCommissionPayment', res.data.payments);
            })
            .catch(e => this.dispatch('snackbar/error', e.response.data.message))
        },

        async getRfDue(context, payload) {
            await axios.post(`get-rf-dues`, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                context.commit('populateDue', res.data.due)
            })
        },

        async saveCommissionPayment(context, payload) {
            let isSuccess = false
            let url = '';

            if(payload.id != null) {
                url = 'update-commission-payment'
            } else {
                url = 'add-commission-payment'
                delete payload.id
            }

            await axios.post(url, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                isSuccess = true
                this.dispatch('snackbar/success', res.data.message)
                // context.dispatch('getCommissionPayments')
            })
            .catch(err => {
                this.dispatch('snackbar/error', err.response.data.message)
            })

            return isSuccess;
        },

        async deleteCommissionPayment(context, id) {
            await axios.post(`delete-commission-payment`, id, {
                headers: {
                    Authorization: this.getters['auth/jwt']
                }
            })
            .then(res => {
                this.dispatch('snackbar/success', res.data.message)
                // context.dispatch('getCommissionPayments')
            })
            .catch(err => {
                this.dispatch('snackbar/error', err.response.data.message)
            })
        }
    }
}